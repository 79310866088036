import { put, takeLatest, call, all } from 'redux-saga/effects';
import { Cookies } from 'react-cookie';
import { checkEnvironment } from 'ui-common/src/lib/utils';
import { unregister } from 'ui-common/src/config/fetchIntercept';
import {
  requestActionsListSuccess,
  requestActionsListFailure,
  requestFeaturesListSuccess,
  requestActivityListSuccess,
  requestImportantActionsListSuccess,
  requestImportantActionsListFailure,
  requestPendingActionsSuccess,
  requestUserListSuccess,
  requestRecentUserListFailure,
  requestRecentUserListSuccess,
  fetchWelcomeTilesSuccess,
  fetchWelcomeTilesFailure,
  fetchRecentRequestsSuccess,
  fetchRecentRequestsFailure,
  fetchHelpRequestsSuccess,
  fetchHelpRequestsFailure,
  fetchBannerRequestsSuccess,
  fetchBannerRequestsFailure,
  fetchAppRecommendationsSuccess,
  fetchAppRecommendationsFailure,
  fetchRequestDetailsSuccess,
  fetchRequestDetailsFailure,
  fetchDefaultWidgetsSuccess,
  fetchDefaultWidgetsFailure,
} from './requestHome-actions';
import {
  UPDATE_KPI_CODE,
  UPDATE_KPI_ATTRIBUTES,
  NEW_KPI_ATTRIBUTES,
  FETCH_FEATURE_NAME_LIST,
} from './kpiForm-actions';
import {
  updateKpiFormCode,
  updateKpiAttributes,
  newKpiAttributes,
  requestFeatureNameList,
} from './kpiForm-saga';

const cookies = new Cookies();

function* requestActionsListSaga() {
  // yield put(requestActionsListSuccess(json));
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const url = `${IDW_ENDPOINT}/users/activity/${cookies.get('user_name')}?home=request`;
  // const url = `http://localhost:9091/users/activity/${cookies.get('user_name')}`;
  const response = yield call(fetch, url, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      'X-NOLOADER': true,
    },
  });
  if (response && response.status === 200) {
    const json = yield call([response, response.json]);
    yield put(requestActionsListSuccess(json));
  } else {
    yield put(requestActionsListFailure('failure'));
  }
}

function* requestFeaturesListSaga() {
  // yield put(requestFeaturesListSuccess(FeaturesMockData));
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  try {
    const url = `${IDW_ENDPOINT}/users/${cookies.get('user_name')}/features?home=request`;
    // const url = `http://localhost:9091/users/activity/${cookies.get('user_name')}`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(requestFeaturesListSuccess(json));
    } else {
      // yield put(requestActionsListFailure('failure'));
    }
  } catch {
    // exception
  }
}

function* requestActivityListSaga() {
  yield put(requestActivityListSuccess());
}

function* requestImportantActionsListSaga() {
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const SERVICE_ENDPOINT = checkEnvironment().ARSMS_API_URL;
  const ECM_ENDPOINT = checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN
    ? checkEnvironment().ECM_URL
    : checkEnvironment().ECM_API_URL;
  try {
    const [historyResponse, approvalResponse, passwordResponse, certiResponse] = yield all([
      // call(fetch, `${SERVICE_ENDPOINT}/requests/list`, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json', 'X-NOLOADER': true, },
      //   body: JSON.stringify({
      //     username: cookies.get('user_name'),
      //     countOnly: true,
      //     filterBy: { requestedFor: cookies.get('user_name'), status: "Pending" },
      //   }),
      // }),
      // call(fetch, `${SERVICE_ENDPOINT}/requests/approval/list`, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json', 'X-NOLOADER': true, },
      //   body: JSON.stringify({
      //     username: cookies.get('user_name'),
      //     filterBy: {},
      //     countOnly: true,
      //   }),
      // }),
      // call(fetch, `${IDW_ENDPOINT}/users/activity/${cookies.get('user_name')}?feature=password`, {
      //   method: 'GET',
      //   headers: {
      //     'Content-Type': 'application/json',
      //     'X-NOLOADER': true,
      //   },
      // }),
      // call(fetch, checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN ? `${ECM_ENDPOINT}/cert/fetchCertificationList` : `${ECM_ENDPOINT}/cert/fetchCertificationList`, {
      //   method: 'POST',
      //   headers: { 'Content-Type': 'application/json', 'X-NOLOADER': true, },
      //   body: JSON.stringify({
      //     certifierUsername: cookies.get('user_name'),
      //     refreshProgress: "N",
      //     offset: "0",
      //     max: "1",
      //     status: "new,inprogress"
      //   }),
      // }),
    ]);
    const finalResponseArray = {
      PendingBeneficiary: {},
      PendingApprovals: {},
      PasswordExpiry: {},
      PendingCertifications: {},
    };
    if (historyResponse && historyResponse.status === 200) {
      try {
        const historyResponsejson = yield call([historyResponse, historyResponse.json]);
        finalResponseArray.PendingBeneficiary.response = historyResponsejson.count;
      } catch {
        // exception
      }
    }
    if (approvalResponse && approvalResponse.status === 200) {
      try {
        const approvalResponsejson = yield call([approvalResponse, approvalResponse.json]);
        finalResponseArray.PendingApprovals.response = approvalResponsejson.count;
      } catch {
        // exception
      }
    }
    if (passwordResponse && passwordResponse.status === 200) {
      try {
        const passwordResponsejson = yield call([passwordResponse, passwordResponse.json]);
        finalResponseArray.PasswordExpiry.response = passwordResponsejson.daysToExpireUserPassword;
      } catch {
        // exception
      }
    }
    if (certiResponse && certiResponse.status === 200) {
      try {
        const certiResponsejson = yield call([certiResponse, certiResponse.json]);
        finalResponseArray.PendingCertifications.response =
          certiResponsejson.totalCount !== undefined ? certiResponsejson.totalCount : 'NA';
      } catch {
        // exception
      }
    }
    yield put(requestImportantActionsListSuccess(finalResponseArray));
  } catch {
    // exception
    const finalResponseArray = {
      PendingBeneficiary: {},
      PendingApprovals: {},
      PasswordExpiry: {},
      PendingCertifications: {},
    };
    yield put(requestImportantActionsListFailure(finalResponseArray));
  }
}
function* requestRecentUserListSaga(action) {
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const url = `${IDW_ENDPOINT}/users/`;
  const defaultBody = {
    userName: cookies.get('user_name'),
    userTypes: ['recentJoiners', ' recentLeavers', 'recentMovers'],
  };
  const response = yield call(fetch, url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-NOLOADER': true,
    },
    body: JSON.stringify(defaultBody),
  });
  if (response && response.status === 200) {
    const json = yield call([response, response.json]);
    yield put(requestRecentUserListSuccess(json));
  }
}

function* requestUserListSaga(action) {
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const MAX = checkEnvironment().REQUEST_HOME_TEAMS_USERS_DISPLAY_COUNT || 100;
  const url = `${IDW_ENDPOINT}/users/`;
  const defaultBody = {
    userName: cookies.get('user_name'),
    sortBy: 'firstname',
    status: 'ALL',
    search: action.searchText || '',
    sortOrder: 'asc',
    max: MAX,
    offset: 0,
    team: true,
  };
  const response = yield call(fetch, url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'X-NOLOADER': true,
    },
    body: JSON.stringify(defaultBody),
  });
  if (response && response.status === 200) {
    const json = yield call([response, response.json]);
    yield put(requestUserListSuccess(json, action.searchText));
  } else {
    yield put(requestRecentUserListFailure());
  }
}

function* requestPendingActionsSaga(action) {
  const IDW_ENDPOINT = checkEnvironment().IDWMS_API_URL;
  const SERVICE_ENDPOINT = checkEnvironment().ARSMS_API_URL;
  try {
    let responseJson = {};
    if (action.requestType === 'Request') {
      const response = yield call(fetch, `${SERVICE_ENDPOINT}/requests/approval/list`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json', 'X-NOLOADER': true },
        body: JSON.stringify({
          username: cookies.get('user_name'),
          filterBy: {},
          page: 'home',
          max: action.maxRecords,
          offset: 0,
          sortBy: 'requestDate',
          sortOrder: 'desc',
        }),
      });
      if (response && response.status === 200) {
        const resp = yield call([response, response.json]);
        if (resp.requests) {
          responseJson = resp;
        }
      }
    } else {
      const response = yield call(
        fetch,
        `${checkEnvironment()?.ECM_URL}/cert/fetchCertificationList`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json', 'X-NOLOADER': true },
          body: JSON.stringify({
            certifierUsername: cookies.get('user_name'),
            refreshProgress: 'N',
            offset: '0',
            max: `${action.maxRecords}`,
            status: 'new,inprogress',
          }),
        }
      );
      if (response && response.status === 200) {
        const resp = yield call([response, response.json]);
        if (resp.certifications) {
          responseJson = resp;
        }
      }
    }
    yield put(requestPendingActionsSuccess(responseJson, action.requestType));
  } catch {
    // exception
  }
}

function* fetchWelcomeTilesSaga() {
  const { ECM_URL } = checkEnvironment();
  try {
    const url = `${ECM_URL}/navigation/tiles`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(fetchWelcomeTilesSuccess(json));
    } else {
      yield put(fetchWelcomeTilesFailure());
    }
  } catch {
    // exception
    yield put(fetchWelcomeTilesFailure());
  }
}

function* fetchRecentRequestsSaga() {
  const { ECM_URL } = checkEnvironment();
  try {
    const url = checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN
      ? `${ECM_URL}/req/fetchRequestHistory`
      : `${ECM_URL}/api/v5/fetchRequestHistory`;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
      body: JSON.stringify({
        username: cookies.get('user_name'),
        max: '5',
        sort: 'requestsubmittedon',
        requestedByExactMatch: cookies.get('user_name'),
        order: 'desc',
      }),
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(fetchRecentRequestsSuccess(json?.requests));
    } else {
      yield put(fetchRecentRequestsFailure());
    }
  } catch {
    // exception
    yield put(fetchRecentRequestsFailure());
  }
}

function* fetchHelpRequestsSaga() {
  const { ECM_URL } = checkEnvironment();
  try {
    const url = `${ECM_URL}/navigation/help`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(fetchHelpRequestsSuccess(json));
    } else {
      yield put(fetchHelpRequestsFailure());
    }
  } catch {
    // exception
    yield put(fetchHelpRequestsFailure());
  }
}

function* fetchBannerRequestsSaga() {
  const { ECM_URL } = checkEnvironment();
  try {
    const url = `${ECM_URL}/navigation/banner`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(fetchBannerRequestsSuccess(json));
    } else {
      yield put(fetchBannerRequestsFailure());
    }
  } catch {
    // exception
    yield put(fetchBannerRequestsFailure());
  }
}

function* fetchAppRecommendationsSaga() {
  const { ECM_URL } = checkEnvironment();
  try {
    const url = checkEnvironment().FEATURE_HTTPONLY_ACCESSTOKEN
      ? `${ECM_URL}/intel/getAppRecommendations`
      : `${ECM_URL}/api/v5/getAppRecommendations`;
    const response = yield call(fetch, url, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
      body: JSON.stringify({
        username: cookies.get('user_name'),
      }),
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(fetchAppRecommendationsSuccess(json?.recommendations));
    } else {
      yield put(fetchAppRecommendationsFailure());
    }
  } catch {
    // exception
    yield put(fetchAppRecommendationsFailure());
  }
}

function* fetchRequestDetailsSaga() {
  const { ECM_URL } = checkEnvironment();
  try {
    const url = `${ECM_URL}/navigation/requestAccessOptions`;
    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      // To check if user has access to request option, if topLevel key is present in response
      if (Object.prototype.hasOwnProperty.call(json, 'topLevel')) {
        yield put(fetchRequestDetailsSuccess(json?.topLevel));
      } else {
        yield put(fetchRequestDetailsFailure());
      }
    } else {
      yield put(fetchRequestDetailsFailure());
    }
  } catch {
    // exception
    yield put(fetchRequestDetailsFailure());
  }
}

function* fetchDefaultWidgetSaga() {
  const { GATEWAY_API_URL } = checkEnvironment();
  try {
    const url = `${GATEWAY_API_URL}/api/idw/config/DEFAULT_HOMEPAGE_WIDGETS`;

    const response = yield call(fetch, url, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'X-NOLOADER': true,
      },
    });
    if (response && response.status === 200) {
      const json = yield call([response, response.json]);
      yield put(fetchDefaultWidgetsSuccess(json));
    } else {
      yield put(fetchDefaultWidgetsFailure());
    }
  } catch {
    // exception
    yield put(fetchDefaultWidgetsFailure());
  }
}

export default function* requestHomeSaga() {
  yield takeLatest('REQUEST_IMPORTANT_ACTIONS_LIST', requestImportantActionsListSaga);
  yield takeLatest('REQUEST_ACTIONS_LIST', requestActionsListSaga);
  yield takeLatest('REQUEST_FEATURES_LIST', requestFeaturesListSaga);
  yield takeLatest('REQUEST_ACTIVITY_LIST', requestActivityListSaga);
  yield takeLatest('REQUEST_TEAM_USER_LIST', requestUserListSaga);
  yield takeLatest('REQUEST_RECENT_USER_LIST', requestRecentUserListSaga);
  yield takeLatest('REQUESTHOME_PENDING_ACTIONS', requestPendingActionsSaga);
  yield takeLatest(UPDATE_KPI_CODE, updateKpiFormCode);
  yield takeLatest(UPDATE_KPI_ATTRIBUTES, updateKpiAttributes);
  yield takeLatest(NEW_KPI_ATTRIBUTES, newKpiAttributes);
  yield takeLatest(FETCH_FEATURE_NAME_LIST, requestFeatureNameList);
  yield takeLatest('FETCH_WELCOME_TILES', fetchWelcomeTilesSaga);
  yield takeLatest('FETCH_RECENT_REQUEST', fetchRecentRequestsSaga);
  yield takeLatest('FETCH_HELP_REQUEST', fetchHelpRequestsSaga);
  yield takeLatest('FETCH_BANNER_REQUEST', fetchBannerRequestsSaga);
  yield takeLatest('FETCH_APP_RECOMMENDATIONS', fetchAppRecommendationsSaga);
  yield takeLatest('FETCH_REQUEST_ACCESS_DETAILS', fetchRequestDetailsSaga);
  yield takeLatest('FETCH_DEFAULT_WIDGET', fetchDefaultWidgetSaga);
}
